export const environment: Environment = {
  production: true,
  lookerGatewayPort: "",
  lookerGatewayService: "looker",
  lookerGatewayVersion: { V1: "/v1" },
  reportsGateway: {
    V1: {
      port: "",
      service: "looker",
      version: "/v1",
      dashboardPath: "/looker/dashboards/",
    },
    V2: {
      port: "",
      service: "embedded-reports",
      version: "/v2",
      dashboardPath: "/looker/dashboards/",
    },
  },
  sentryDsn:
    "https://442198970eee48dfa6c349e02a483f11@o4505801445605376.ingest.us.sentry.io/4507052187254786",
  sentrySampleRate: 0.5,
  sentryTracesSampleRate: 0.5,
  sentryReplaysSessionSampleRate: 0.5,
  sentryReplaysOnErrorSampleRate: 1.0,
  replayUserBlacklist: [
    "juilee.kulkarni+test@poppulo.com",
    "application.monitoring@genpact.com",
    "nanasyntheticsubaccount@autotest.newsweaver.com",
  ],
};

export interface Environment {
  readonly production: boolean;
  readonly lookerGatewayPort: string;
  readonly lookerGatewayService: string;
  readonly lookerGatewayVersion: { [key: string]: string };
  readonly reportsGateway: { [key: string]: { [key: string]: string } };
  readonly sentryDsn: string;
  readonly sentrySampleRate: number;
  readonly sentryTracesSampleRate: number;
  readonly sentryReplaysSessionSampleRate: number;
  readonly sentryReplaysOnErrorSampleRate: number;
  readonly replayUserBlacklist: string[];
}
